/*
 * @license
 * Copyright 2017 Google LLC All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"); you may not use this file except
 * in compliance with the License. You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under the
 * License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing permissions and
 * limitations under the License.
 */

export const config = {
  apiKey: "AIzaSyA2lTOyfWxgvWC46-zOVxr_b8M5_Eh1S70",
  authDomain: "fb-sa-upgraded.firebaseapp.com",
  databaseURL: "https://fb-sa-upgraded.firebaseio.com",
  projectId: "fb-sa-upgraded",
  storageBucket: "fb-sa-upgraded.appspot.com",
  messagingSenderId: "1085102361755",
  appId: "1:1085102361755:web:c6af47eadd27f50c5dca3e",
  measurementId: "G-D2VP42Z2MC"
};
